html, body {
  height: 100vh;
  width: 100vw;
  @include clearfix;
}

.App {
  margin: auto;
  width: 100%;
  padding: 10px;
  @include clearfix;
}

.row400 {
  height: 400px;
  width: 400px;
}

.square {
  background-color: #1d29157b;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%232d2d2d' fill-opacity='0.66' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}

.selectImage {
  width: 400px !important;
}